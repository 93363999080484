import React from 'react';
import PropTypes from 'prop-types';
import CheckedImage from './CheckedImage';
import dotsBorder from './../../../res/images/benefits/dots-border.svg';

const SingleBenefit = ({ image, title, description, alt }) => {
  const splitTitle = title.split(' ');
  return (
    <div className={'benefit'} style={{ backgroundImage: `url(${dotsBorder})` }}>
      <CheckedImage image={image} alt={alt}/>
      <p className={'title'}>{splitTitle.shift()}<br/>{splitTitle.join(' ')}</p>
      <p className={'description'}>{description}</p>
    </div>
  );
};

SingleBenefit.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default SingleBenefit;
