import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/Toolbar';
import Intro from '../sections/intro/Intro';
import Solutions from '../sections/solutions/Solutions';
import Divider from '../mdc/Divider';
import Benefits from '../sections/benefits/Benefits';
import Model from '../sections/model/Model';
import About from '../sections/about/About';
import Contact from '../sections/contact/Contact';
import Footer from '../sections/footer/Footer';
import UserDataContext from '../sections/solutions/dialogs/DataContext';
// import FloatingButtons from '../sections/floating-buttons/Floating-buttons';

const Home = () => {
  return (<>
    <DrawerWrapper/>
    <Toolbar/>
    <Intro/>
    <UserDataContext>
      <Solutions/>
    </UserDataContext>
    <Divider/>
    <Benefits/>
    <Divider/>
    <Model/>
    <Divider/>
    <About/>
    <Divider/>
    <Contact/>
    <Divider/>
    <Footer/>
    {/* <FloatingButtons /> */}
  </>);
};

export default Home;
