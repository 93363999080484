import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/Toolbar';
import SiteBody from '../mdc/SiteBody';
import CxSummitDemo from '../sections/cx-summit/cx-summit';

const CxSummit = () => {
  return (
    <>
      <DrawerWrapper/>
      <Toolbar/>
      <SiteBody>
        <CxSummitDemo/>
      </SiteBody>
    </>
  );
};

export default CxSummit;
