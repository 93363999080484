import { api, escalateError, getResponseData } from './index';

export default class ChatApi {
  static getChatDetails(key) {
    return api.get(`/chats?key=${key}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static generateMessage(userData) {
    return api.post('whatsapp', userData)
      .then(getResponseData)
      .catch(escalateError);
  }

  static sendInfo(userData) {
    return api.post('save-info', userData)
      .then(getResponseData)
      .catch(escalateError);
  }
}
