/* eslint-disable max-nested-callbacks */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@rmwc/chip';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { Snackbar } from '@rmwc/snackbar';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell } from '@rmwc/grid';
import { CircularProgress } from '@rmwc/circular-progress';
import BPSChat from '@bpsmart/chat-component';
import ChatTitleBar from '../chat-title-bar/ChatTitleBar';
import BpSmartLogo from '../../../res/images/solutions/Logo_BPS.svg';
import ChatApi from '../../../api/ChatApi';
import CxSummitApi from '../../../api/Cx-Summit';
import { uuid } from 'uuidv4';
import './cx-summit.css';

const CxSummit = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [lastName, setLastName] = useState('');
  const [position, setPosition] = useState('');
  const [usersData, setUsersData] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [demoData, setDemoData] = useState(null);
  const [load, setLoad] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [checkButton, setCheckButton] = useState(false);
  const [signupDate, setSignupDate] = useState(new Date());
  const [id, setId] = useState(new Date());

  useEffect(() => {
    CxSummitApi.getInfo()
      .then((res) => {
        setUsersData(res.data);
      })
      .catch(() => {
        setSnackbarMessage(t('error'));
      });
    ChatApi.getChatDetails('BPSmartPresentacionEventos')
      .then((res) => {
        if (res.success) setDemoData(res.data);
        else setDemoData({});
      })
      .catch(() => {
        setDemoData({});
      });
    // eslint-disable-next-line
  }, []);

  const validateFields = () => {
    const regex = /[,áéíóúàâãäåèêëìîïòôõö�ûü_!<>¡@+*#{}-~ç©¬Æ"':.;()]+/g;
    signupDate.setHours(signupDate.getHours() - 5);
    if (!name || !lastName || !company || !phone || !email || !position) {
      setIsOpenChat(isOpenChat);
      setSnackbarMessage(t('missing-fields'));
    }
    if (regex.test(name) || regex.test(lastName) || regex.test(company) || regex.test(position)) {
      setSnackbarMessage(t('regex-error'));
      return;
    }
    if (phone && (phone.length < 9 || phone.length > 10)) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }
    if (name.length < 4 || lastName.length < 4 || company.length < 4) {
      return;
    }
    for (let i = 0; i < usersData.length; i++) {
      const phoneNumber = usersData[i].phone.indexOf(phone);
      const mail = usersData[i].email.indexOf(email);
      if (phoneNumber !== -1 || mail !== -1) {
        setSnackbarMessage(t('data-error'));
        return;
      }
    }
    setLoad(true);
    setId(uuid());
    CxSummitApi.sendInfo({ name, lastName, company, phone, email, position, signupDate, privacy })
      .then((res) => {
        setIsClicked(false);
        setCheckButton(true);
        setCode(res.data);
        if (res.success) {
          setSnackbarMessage(t('sign-up-ok'));
          setLoad(false);
          setTimeout(() => {
            setIsOpenChat(!isOpenChat);
          }, 2000);
        }
      })
      .catch(() => {
        setSnackbarMessage(t('sign-up-error'));
      });
  };

  const renderChatTitleBar = () => {
    return (<ChatTitleBar onClose={() => setIsOpenChat(false)} />);
  };

  const renderChatTrivia = () => {
    if (!isOpenChat) return (<></>);
    return (<div className={'trivia-component-container'}>
      <BPSChat ally={'bpsmart'}
               botName={'BPSmartPresentacionEventos'}
               config={{ provider: 'watson', dialogId: '16ec7ac5-6a62-47d6-a92b-f4ee184d6299' }}
               token={demoData.token}
               includeTitleBar={true}
               titleBarContent={renderChatTitleBar()}
               infConversation={{
          clientName: name,
          conversation: {},
          initMessagge: `holdingname name:'${name}' lastName:'${lastName}' phone:'${phone}' `
            + `email:'${email}' company:'${company}' position:'${position}' code:'${code}'`,
          id,
          campaignId: 'H3IcRNQb9F5DUXLPbKxr',
          integrationId: 'a5947d3e-75d3-488d-b78d-c96760c5d84b',
          channel: 'website',
        }}
      />
    </div>);
  };

  return (
    <div className={'cx-summit'}>
      <img src={BpSmartLogo} alt={'BPSmart Logo'} className={'bpsmart-logo-cxsummit'} />
      <p style={{ textAlign: 'center' }}>{t('summit-info')}</p>
      <br />
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid style={{ padding: 0 }}>
          <GridCell span={12}>
            <TextField
              outlined label={t('name')} value={name} minLength={4}
              onChange={(e) => setName(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined label={t('last-name')} value={lastName} minLength={4}
              onChange={(e) => setLastName(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined label={t('phone')} type={'number'} value={phone}
              onChange={(e) => setPhone(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined label={t('email')} type={'email'} value={email}
              onChange={(e) => setEmail(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined label={t('company')} value={company} minLength={4}
              onChange={(e) => setCompany(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined label={t('position')} value={position}
              onChange={(e) => setPosition(e.target.value.toString())} />
          </GridCell>
          <GridCell span={12} />
        </Grid>
        <Checkbox className={'privacy-data'} label={t('authorization')}
                  disabled={checkButton} onChange={(e) => {
            setPrivacy(e.target.checked);
            setIsClicked(e.target.checked);
          }} />
        <Snackbar
          style={{ textAlign: 'center' }}
          open={snackbarMessage.length > 0}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          dismissesOnAction />
        <br />
        {load
          ? <Chip className={'load-chip'} label={t('load')}
                  raised icon={<CircularProgress />} />
          : <Button className={'button-sign'} raised disabled={!isClicked}
                    label={t('sign-up')}
                    onClick={() => {
              validateFields();
              setSignupDate(new Date());
            }} />
        }
        {isOpenChat ? renderChatTrivia() : <></>}
      </form>
    </div>
  );
};

export default CxSummit;
