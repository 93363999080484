import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@rmwc/button';
import MDIcon from '../../mdc/MDIcon';
import bpSmartCall from '../../../res/images/solutions/Logo_BPS.svg';
import './chat-title-bar.css';

const ChatTitleBar = ({ title = 'BPSmart', onClose }) => {
  return (<>
    <div>
      <img src={bpSmartCall} alt={'BPSmart Logo'}/>
      <span>{title}</span>
    </div>
    <div>
      <Button onClick={onClose}>
        <MDIcon icon={'close'}/>
      </Button>
    </div>
  </>);
};

ChatTitleBar.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default ChatTitleBar;
