import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const DataContext = createContext('solutionData');

const reducer = (data, action) => {
  if (action) {
    return {
        ...data,
        userData: action.userData,
    };
  }
  return { ...data };
};

const UserDataContext = ({ children }) => {
  const [dataUser, dispatchDataUser] = useReducer(reducer, { userData: [] });
  return (
    <DataContext.Provider value={{ dataUser, dispatchDataUser }}>
      {children}
    </DataContext.Provider>
  );
};

UserDataContext.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export { DataContext };
export default UserDataContext;
