import React from 'react';
import { useTranslation } from 'react-i18next';
import SolutionRow from './SolutionRow';
import SolutionData from './dialogs/DialogProvider';
import DialogWeb from './dialogs/Dialog';
import Utils from '../../../utils/Utils';
import cobranzas from './../../../res/images/solutions/cobranzas.svg';
import ventas from './../../../res/images/solutions/ventas.svg';
import atencion from './../../../res/images/solutions/atencion.svg';
import cobranzasVideo from './../../../res/images/solutions/cobranzas.m4v';
import retailVideo from './../../../res/images/solutions/retail.m4v';
import telefoniaGif from './../../../res/images/solutions/telefonia.gif';
import puntosCrecimiento from './../../../res/images/solutions/puntos-crecimiento-a.svg';
import puntosCrecimientoAlt from './../../../res/images/solutions/puntos-crecimiento-b.svg';
import './solutions.css';

const Solutions = () => {
  const { t } = useTranslation();
  return (
    <div
      id={'solutions'}
      style={{
        backgroundColor: '#fff',
        paddingBottom: '4rem',
      }}>
      <div className={'section solutions'}>
        <div className={'column'} style={{ backgroundImage: `url(${puntosCrecimiento})` }}>
          <p className={'section-pre-title'}>{t('our')}</p>
          <p className={'section-title'}>
            {(t('smart-solutions').split(' ') || []).map((it, i) => {
              const last = i >= (t('smart-solutions').split(' ') || []).length - 1
                ? (<></>)
                : (<><br /></>);
              return (<React.Fragment key={i}>{it}{last}</React.Fragment>);
            })}
          </p>
        </div>
        <div className={'column right description'}
             style={{ backgroundImage: `url(${puntosCrecimientoAlt})` }}>
          <p className={'section-body'}>{t('solutions-a')}</p>
          <br />
          <p
            className={'section-body'}
            dangerouslySetInnerHTML={{ __html: Utils.parseHtml(t('solutions-b')) }} />
        </div>
        <br />
      </div>
      <SolutionData>
        <SolutionRow
          rowKey={'smart-debt-collection'}
          icon={cobranzas}
          preview={cobranzasVideo}
          description={[t('sdc-a'), t('sdc-b')]}
          link={`${t('telegram-bot-debt')}?start=${t('hello')}`} />
        <SolutionRow
          rowKey={'smart-sale'}
          icon={ventas}
          preview={retailVideo}
          description={[t('ss-a'), t('ss-b')]}
          link={`${t('telegram-bot-sale')}?start=${t('hello')}`} />
        <SolutionRow
          isImage
          rowKey={'smart-customer-service'}
          icon={atencion}
          preview={telefoniaGif}
          description={[t('scs-a'), t('scs-b')]}
          link={`${t('telegram-bot-customer')}?start=${t('hello')}`} />
        <DialogWeb />
      </SolutionData>
    </div>
  );
};

export default Solutions;
