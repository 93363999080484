/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@rmwc/snackbar';
import { DialogContent } from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { SolutionContext } from './DialogProvider';
import { DataContext } from './DataContext';
import ChatApi from '../../../../api/ChatApi';
import DialogChat from './DialogChat';
import ChatTitleBar from '../../chat-title-bar/ChatTitleBar';
import { ServicesApi } from '../../../../api/Services';
import bpSmartLogo from './../../../../res/images/solutions/Logo_BPS.svg';
import { firebaseAnalytics } from '../../../../firebase/firebase';
import './call-dialog.css';

const DialogInfo = (option = '') => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [enabled, setEnabled] = useState(true);
  const { dataUser, dispatchDataUser } = useContext(DataContext);
  const { data, dispatchData } = useContext(SolutionContext);
  const [dialogId, setDialogId] = useState('');
  const [chatData, setChatData] = useState([]);

  const selectId = (language) => {
    if (language) {
      if (language === 'es') {
        switch (data.key) {
          case 'smart-debt-collection':
            return 'cobranza';
          case 'smart-sale':
            return 'venta';
          case 'smart-customer-service':
            return 'servicio';
          default:
            return null;
        }
      } else { return data.key; }
    }
    return null;
  };

  useEffect(() => {
    setDialogId(selectId(language || dataUser.userData.lang));
    if (dialogId) {
      ChatApi.getChatDetails(dialogId)
      .then((data) => {
        if (data.success) {
          setChatData(data.data);
        } else {
          setChatData({});
        }
      })
      .catch(() => {
        setChatData({});
      });
    }
      // eslint-disable-next-line
  }, [language, dialogId]);

  useEffect(() => {
    if (dataUser.userData.length !== 0) {
      setName(dataUser.userData.clientName);
      setPhone(dataUser.userData.number);
      setCountry(dataUser.userData.country);
      setLanguage(dataUser.userData.lang);
    }
    // eslint-disable-next-line
  }, []);

  const generateMessage = async (option) => {
    if (!enabled) return;
    if (!name || name.length <= 0
      || !phone || phone.length <= 0
      || !country || country.length <= 0) {
      setSnackbarMessage(t('missing-fields'));
      return;
    }

    if (country !== '51' && phone.length !== 10) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }
    if (country === '51' && phone.length !== 9) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }

    ServicesApi();
    const userData = {
      ...chatData,
      number: `${country}${phone}`,
      clientName: name,
      country,
      lang: language,
    };

    const userDataContext = {
      userData: {
        clientName: userData.clientName,
        number: phone,
        country,
        key: chatData.key,
        lang: language,
        type: 'chat',
        startTime: new Date().toISOString(),
      },
    };
    ChatApi.sendInfo(userDataContext);

    dispatchDataUser(userDataContext);

    if (option !== 'chat') {
      try {
        const chatResponse = await ChatApi.generateMessage(userData)
          .then((data) => {
            return data.success ? data.success : null;
          })
          .catch(() => null);
        if (chatResponse) {
          setSnackbarMessage(t('whatsapp-send'));
          firebaseAnalytics.logEvent(`${dialogId}-whatsapp-website-send`);
        } else {
          setSnackbarMessage(t('message-error'));
          firebaseAnalytics.logEvent(`${dialogId}-whatsapp-website-error`);
        }
      } catch (e) {
        firebaseAnalytics.logEvent(`${dialogId}-whatsapp-website-error`);
        setSnackbarMessage(e.message || t('error'));
      }
      setEnabled(false);
      setTimeout(() => {
        dispatchData({ show: false });
      }, 1000);
    } else {
      firebaseAnalytics.logEvent(`${dialogId}-chat-website-clicked`);
      setOpen(true);
    }
  };

  const renderDialog = () => {
    switch (option.option) {
      case 'chat': {
        return (<div className={'dialog-field'}>
          <Button
            raised
            onClick={() => { generateMessage(option.option); }}
            label={t('start-chat')} />
        </div>);
      }
      case 'whatsapp':
        return (<div className={'dialog-field'}>
          <Button
            raised
            onClick={generateMessage}
            label={t('generate-message')} />
        </div>);
      default:
        return (<></>);
    }
  };

  return (<DialogContent>
    <img src={bpSmartLogo} alt={'BPSmart Logo'} className={'call-dialog-logo'}/>
    <p>{t('add-info')}</p>
    <div className={'dialog-field'}>
      <TextField
        required outlined
        disabled={!enabled}
        value={name} onChange={(e) => setName(e.target.value.toString())}
        label={t('name')} placeholder={t('name')}
        type={'text'}/>
    </div>
    <div className={'dialog-field'}>
      <TextField
        required outlined
        disabled={!enabled}
        value={phone} onChange={(e) => setPhone(e.target.value.toString())}
        label={t('phone')} placeholder={t('phone')}
        type={'number'}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        disabled={!enabled}
        value={country} onChange={(e) => setCountry(e.target.value.toString())}
        label={t('country')}
        options={[{ label: 'Colombia', value: '57' },
          { label: 'Perú', value: '51' },
          { label: 'U.S.A', value: '1' }]}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        disabled={!enabled}
        value={language} onChange={(e) => setLanguage(e.target.value.toString())}
        label={t('language')}
        options={[{ label: t('spanish'), value: 'es' }, { label: t('english'), value: 'en' }]}/>
    </div>
    <div className={'dialog-field'}>
      { renderDialog() }
    </div>
    <DialogChat
      show={open}
      config={chatData}
      titleBarContent={
        <ChatTitleBar title={t(data.key)} onClose={() => { dispatchData(false); }}/>
      }
    />
    <Snackbar
      open={snackbarMessage.length > 0}
      onClose={() => setSnackbarMessage('')}
      message={snackbarMessage}
      dismissesOnAction/>
  </DialogContent>);
};

DialogInfo.propTypes = {
  option: PropTypes.string.isRequired,
};

export default DialogInfo;
