import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/storage';
import firebaseConfig from './../firebase-bpsmart.json';

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();
export const firebaseStorage = firebase.storage();
