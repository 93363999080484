import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Snackbar } from '@rmwc/snackbar';
import { DialogContent, Dialog } from '@rmwc/dialog';
import { SolutionContext } from './DialogProvider';
import CallsApi from '../../../../api/CallsApi';
import bpSmartCall from './../../../../res/images/solutions/Logo_BPS.svg';
import './call-dialog.css';

const DialogCalling = (props) => {
  const { t } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { userData } = props;
  const { show } = props;
  const [open, setView] = useState(false);
  const [callOut, setCallOut] = useState('calling-status');
  const [callStatus, setCallStatus] = useState(t('calling'));
  const { dispatchData } = useContext(SolutionContext);

  useEffect(() => {
        setView(show);
  }, [userData, show]);

  const startCall = async () => {
    setCallOut('calling-status');
    setCallStatus(t('calling'));
    try {
      const callResponse = await CallsApi.generateCall(userData)
        .then((data) => {
          if (data.success) return data.data || {};
          return null;
        })
        .catch(() => null);
      if (callResponse) {
        setSnackbarMessage(t('call-sent'));
        endCall();
      } else {
        setSnackbarMessage(t('error'));
        endCall();
      }
    } catch (e) {
      setSnackbarMessage(e.message || t('error'));
      endCall();
    }
  };

  const endCall = () => {
    setCallOut('callout');
    setCallStatus('Finalizado');
    setTimeout(() => {
      setView(false);
      dispatchData({ show: false });
    }, 1000);
  };

  return (<Dialog
            open={open}
            onOpen={startCall}
            onClose={(evt) => {
              setView(false);
            }}
            >
    <DialogContent>
      <img src={bpSmartCall} alt={'BPSmart Logo'} className={'call-dialog-logo'}/>
      <div className={`calling ${callOut}`}><h3>{ callStatus } </h3></div>
      <div className={'calling-info'}>
        <h4>{userData.number}</h4>
      </div>
      <div className={'calling-info'}>
        <h4>{userData.clientName}</h4>
      </div>
      <Snackbar
        open={snackbarMessage.length > 0}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        dismissesOnAction/>
    </DialogContent>
  </Dialog>);
};

DialogCalling.propTypes = {
  userData: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DialogCalling;
