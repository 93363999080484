export default class Utils {
  static preventTextInNumberInput(e) {
    const key = e.keyCode || e.which || -1;
    if (key < 48 || key > 57) e.preventDefault();
  }

  static validateEmail(email) {
    try {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(email));
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return false;
    }
  }

  static parseHtml(content) {
    if (!content || content.length <= 0 || content.toString().includes('<script')) {
      return '';
    } else {
      return content;
    }
  }
}
