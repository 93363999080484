import React from 'react';
import { useTranslation } from 'react-i18next';
import model from './../../../res/images/model/model.svg';
import puntosCrecimiento from './../../../res/images/solutions/puntos-crecimiento-a.svg';
import puntosCrecimientoAlt from './../../../res/images/solutions/puntos-crecimiento-b.svg';
import './model.css';

const Model = () => {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: '#fff' }} id={'model'}>
      <div className={'section model'}>
        <div className={'column'} style={{ backgroundImage: `url(${puntosCrecimiento})` }}>
          <p className={'section-pre-title'}>{t('our-2')}</p>
          <p className={'section-title'}>{t('model')}</p>
          <br/>
          <p className={'section-body'} style={{ maxWidth: '92%' }}>
            {t('model-content-a')}<br/><br/>
            {t('model-content-b')}<br/><br/>
            {t('model-content-c')}
          </p>
        </div>
        <div className={'column right'} style={{ backgroundImage: `url(${puntosCrecimientoAlt})` }}>
          <img src={model} alt={t('model')}/>
        </div>
      </div>
    </div>
  );
};

export default Model;
