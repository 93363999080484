import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@rmwc/button';
import ScrollingLink from '../../mdc/drawer/ScrollingLink';
import bpsmartWhite from './../../../res/images/bpsmart-logo-white.svg';
import { firebaseAnalytics } from '../../../firebase/firebase';
import './footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const ADMIN_URL = process.env;

  const handleContent = (section) => {
    firebaseAnalytics.logEvent(`${section}-website-visited`);
  };

  return (<>
    <div className={'footer'}>
      <ScrollingLink to={'/'}>
        <Button className={'logo'}
                onClick={() => handleContent('home')}>
          <img src={bpsmartWhite} alt={t('bpsmart-logo')} />
        </Button>
      </ScrollingLink>
      <ScrollingLink to={'#solutions'}>
        <Button label={t('solutions')}
                onClick={() => handleContent('solutions')} />
      </ScrollingLink>
      <ScrollingLink to={'#benefits'}>
        <Button label={t('benefits')}
                onClick={() => handleContent('benefits')} />
      </ScrollingLink>
      <ScrollingLink to={'#model'}>
        <Button label={t('model')}
                onClick={() => handleContent('model')} />
      </ScrollingLink>
      <ScrollingLink to={'#about'}>
        <Button label={t('about')}
                onClick={() => handleContent('about')} />
      </ScrollingLink>
      <ScrollingLink to={'#contact'}>
        <Button label={t('contact')}
                onClick={() => handleContent('contact')} />
      </ScrollingLink>
      <a href={ADMIN_URL.REACT_APP_ADMIN} target={'_blank'} rel={'noopener noreferrer'} >
        <Button label={t('clients-access')}
                onClick={() => handleContent('clients-access')} />
      </a>
      <div className={'social'}>
        <Button
          icon={{
            icon: 'facebook',
            strategy: 'className',
            basename: 'mdi',
            prefix: 'mdi-',
          }}
          tag={'a'} className={'icon-link'}
          href={'https://www.facebook.com/BPSmart-100476808279196'} target={'_blank'}
          rel={'noopener noreferrer'}
          onClick={() => handleContent('facebook')} />
        <Button
          icon={{
            icon: 'linkedin',
            strategy: 'className',
            basename: 'mdi',
            prefix: 'mdi-',
          }}
          tag={'a'} className={'icon-link'}
          href={'https://linkedin.com'} target={'_blank'}
          rel={'noopener noreferrer'}
          onClick={() => handleContent('linkedin')} />
      </div>
    </div>
    <div className={'copyright'}>
      <p>COPYRIGHT © {new Date().getFullYear()} BPSMART ~ {t('all-rights-reserved')
        .toUpperCase()}</p>
    </div>
  </>);
};

export default Footer;
