import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SingleLanguageOnly = ({ lang, children }) => {
  const { i18n } = useTranslation();
  if (!i18n) return (<>{children}</>);
  if (!i18n.language || i18n.language !== lang) return (<></>);
  return (<>{children}</>);
};

SingleLanguageOnly.propTypes = {
  lang: PropTypes.string.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default SingleLanguageOnly;
