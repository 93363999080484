import React, { useContext, useState, useEffect } from 'react';
import { DialogContent, Dialog } from '@rmwc/dialog';
import BPSChat from '@bpsmart/chat-component';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataContext } from './DataContext';
import './chat-dialog.css';
import { firebaseStorage } from '../../../../firebase/firebase';

const DialogChat = ({ titleBarContent = null, show = false, config = {} }) => {
  const { t } = useTranslation();
  const { dataUser } = useContext(DataContext);
  const [open, setView] = useState(false);

  useEffect(() => {
    setView(show);
}, [show]);

  const renderLoadingOrEmpty = () => {
    if (!dataUser.userData) return (<p>{t('loading')}…</p>);
    return (<p>{t('error')}!</p>);
  };

  const renderDialog = () => {
    if (!config || !config.token || config.token.length <= 0
        || dataUser.userData.length <= 0 || !show) {
      return (
        <Dialog open={show}>
          <DialogContent>{renderLoadingOrEmpty()}</DialogContent>
        </Dialog>);
    }
    return (
      <Dialog
        open={open}
        onClose={() => {
          setView(false);
        }}>
        <DialogContent style={{ padding: '0' }}>
          <BPSChat
            ally={'bpsmart'}
            botName={(config.botName).toLowerCase().replace(/\s/g, '-')}
            config={{ provider: config.provider, dialogId: config.botId }}
            token={config.token || ''}
            includeTitleBar={true}
            titleBarContent={titleBarContent}
            firebase={firebaseStorage}
            infConversation={{
              clientName: dataUser.userData.clientName,
              sendText: { text: `${config.keyWord} ${dataUser.userData.clientName}`, type: 'text' },
              conversation: [],
              integrationId: config.integrationId,
              dialogId: config.botId,
              provider: config.provider,
              channel: 'website',
              campaignId: config.campaignId,
              advisor: false,
            }}/>
        </DialogContent>
      </Dialog>);
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      {renderDialog()}
    </div>
  );
};

DialogChat.propTypes = {
  titleBarContent: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
  show: PropTypes.bool,
  config: PropTypes.any,
};

export default DialogChat;
