import React from 'react';
import PropTypes from 'prop-types';

const MDIcon = ({ icon, size = 24, className = '', style = {} }) => (
  <span className={`mdi mdi-${size}px mdi-${icon} ${className}`} style={style}/>);

MDIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default MDIcon;
