import React from 'react';
import { useTranslation } from 'react-i18next';
import SingleBenefit from './SingleBenefit';
import implementacion from './../../../res/images/benefits/implementacion.svg';
import continuidad from './../../../res/images/benefits/continuidad.svg';
import seguridad from './../../../res/images/benefits/seguridad.svg';
import escalabilidad from './../../../res/images/benefits/escalabilidad.svg';
import eficiencia from './../../../res/images/benefits/eficiencia.svg';
import './benefits.css';

const Benefits = () => {
  const { t } = useTranslation();
  return (
    <div
      id={'benefits'}
      style={{
        backgroundColor: '#fff',
        paddingBottom: '4rem',
      }}>
      <div className={'section solutions'}>
        <div className={'column'}>
          <p className={'section-pre-title'}>{t('our-main')}</p>
          <p className={'section-title'}>{t('benefits')}</p>
        </div>
      </div>
      <div className={'benefits-grid'}>
        <SingleBenefit
          image={implementacion} alt={t('quick-implementation')}
          title={t('quick-implementation')}
          description={t('qi-desc')}/>
        <SingleBenefit
          image={continuidad} alt={t('business-continuity')}
          title={t('business-continuity')}
          description={t('bc-desc')}/>
        <SingleBenefit
          image={seguridad} alt={t('total-security')}
          title={t('total-security')}
          description={t('ts-desc')}/>
        <SingleBenefit
          image={escalabilidad} alt={t('max-scalability')}
          title={t('max-scalability')}
          description={t('ms-desc')}/>
        <SingleBenefit
          image={eficiencia} alt={t('cost-efficiency')}
          title={t('cost-efficiency')}
          description={t('ce-desc')}/>
      </div>
    </div>
  );
};

export default Benefits;
