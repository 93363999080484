/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@rmwc/button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CallSVG } from './../../../res/images/solutions/call.svg';
import { ReactComponent as ChatSVG } from './../../../res/images/solutions/chat.svg';
import { ReactComponent as WhatsAppSVG } from './../../../res/images/solutions/Whatsapp.svg';
import { ReactComponent as TelegramSVG } from './../../../res/images/solutions/Telegram.svg';
import fondoWhatsApp from './../../../res/images/solutions/FondoWhatsapp.svg';
import { SolutionContext } from './dialogs/DialogProvider';

const SolutionRow = ({ rowKey, description, icon, preview, onTry, isImage = false, link }) => {
  const { dispatchData } = useContext(SolutionContext);
  const { t } = useTranslation();

  const sendData = (option) => {
    dispatchData({ type: 'show', key: rowKey, option });
  };

  return (
    <div
      className={'section'}
      style={{
        paddingTop: '3rem',
        paddingBottom: '3rem',
      }}>
      <div className={'solution-content'}>
        <img alt={t(rowKey)} src={icon} className={'solution-icon'} />
        <div style={{ margin: '1.4rem' }}>
          <p className={'solution-title'}>{t(rowKey)}</p>
          <br />
          <p className={'section-body'}>
            {(description || []).map((it, i) => {
              const last = i >= (description || []).length - 1 ? (<></>) : (<><br /><br /></>);
              return (<React.Fragment key={i}>{it}{last}</React.Fragment>);
            })}
          </p>
          <br />
          <div className={'test-btns-container'}>
            <p>{t('try-demo')}</p>
            <div>
              <Button
                raised
                className={'test-btn'}
                onClick={() => { sendData('chat'); }}>
                <ChatSVG />
              </Button>
              <Button
                raised
                className={'test-btn'}
                style={{ backgroundImage: `url(${fondoWhatsApp})` }}
                onClick={() => { sendData('whatsapp'); }}>
                <WhatsAppSVG />
              </Button>
              {rowKey === 'smart-sale'
                ? ''
                : <Button
                    raised
                    className={'test-btn'}
                    onClick={() => { sendData('call'); }}>
                  <CallSVG />
                </Button>
              }
              <Button raised
                      className={'test-btn'}
                      onClick={() => window.open(`${link}`, '_blank')}>
                <TelegramSVG />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={'solution-preview-container'}>
        {isImage
          ? (<img className={'solution-preview'} alt={t(rowKey)} src={preview} />)
          : (<video loop muted autoPlay className={'solution-preview'} src={preview} />)}
      </div>
    </div>
  );
};

SolutionRow.propTypes = {
  rowKey: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.any.isRequired,
  preview: PropTypes.any,
  onTry: PropTypes.func,
  isImage: PropTypes.bool,
  link: PropTypes.string.isRequired,
};

export default SolutionRow;
