import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialWrapper from './components/mdc/MaterialWrapper';
import Home from './components/sites/Home';
import NotFound from './components/sites/NotFound';
import SummitDemo from './components/sites/CxSummit';
import { ServicesApi } from './api/Services.js';

const App = () => {
  ServicesApi();

  const { i18n } = useTranslation();

  const changeLanguage = (code) => {
    if (i18n) i18n.changeLanguage(code);
  };

  const deviceLanguage = (navigator.languages && navigator.languages[0])
    || navigator.language || navigator.userLanguage || '';

  useEffect(() => {
    if (deviceLanguage.startsWith('es')) {
      changeLanguage('es');
    } else {
      changeLanguage('en');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <MaterialWrapper>
        <Switch>
          <Route exact path={'/'} component={Home} />
          <Route exact path={'/cx-summit'}>
            <SummitDemo />
          </Route>
          <Route><NotFound /></Route>
        </Switch>
      </MaterialWrapper>
    </BrowserRouter>
  );
};

export default App;
