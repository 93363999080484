import React from 'react';
import { useTranslation } from 'react-i18next';
import Utils from '../../../utils/Utils';
import about from './../../../res/images/about/Nosotros.svg';
import './about.css';
import SingleLanguageOnly from '../SingleLanguageOnly';

const About = () => {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: '#fff' }} id={'about'}>
      <div className={'section solutions'}>
        <div className={'column'}>
          <p className={'section-pre-title'}>{t('about-us-about')}</p>
          <p className={'section-title'}>{t('about-us-us')}</p>
          <br/>
          <p
            className={'section-body'} style={{ maxWidth: '92%' }}
            dangerouslySetInnerHTML={{ __html: Utils.parseHtml(t('about-us-a')) }}/>
          <br/>
          <p className={'section-body'} style={{ maxWidth: '92%' }}>{t('about-us-b')}</p>
        </div>
        <div className={'column right'}>
          <div className={'about-img'}>
            <div className={'first'}>
              <SingleLanguageOnly lang={'es'}>
                <span className={'primary'}>{t('tech-innovation-a')}</span>
                <span>{t('tech-innovation-b')}</span>
              </SingleLanguageOnly>
              <SingleLanguageOnly lang={'en'}>
                <span>{t('tech-innovation-a')}</span>
                <span className={'primary'}>{t('tech-innovation-b')}</span>
              </SingleLanguageOnly>
            </div>
            <div className={'second'}>
              <div className={'child'}>
                <span>{t('human-talent-a')}</span>
                <span className={'primary'}>{t('human-talent-b')}</span>
              </div>
              <img src={about} alt={'About'}/>
              <div className={'child'}>
                <SingleLanguageOnly lang={'es'}>
                  <span className={'primary'}>{t('results-a')}</span>
                  <span>{t('results-b')}</span>
                </SingleLanguageOnly>
                <SingleLanguageOnly lang={'en'}>
                  <span>{t('results-a')}</span>
                  <span className={'primary'}>{t('results-b')}</span>
                </SingleLanguageOnly>
              </div>
            </div>
            <div className={'third'}>
              <SingleLanguageOnly lang={'es'}>
                <span>{t('processes-a')}</span>
                <span className={'primary'}>{t('processes-b')}</span>
              </SingleLanguageOnly>
              <SingleLanguageOnly lang={'en'}>
                <span className={'primary'}>{t('processes-a')}</span>
                <span>{t('processes-b')}</span>
              </SingleLanguageOnly>
            </div>
          </div>
        </div>
        <br/><br/>
      </div>
      <br/><br/>
    </div>
  );
};

export default About;
