import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const SolutionContext = createContext('solutionData');

const reducer = (data, action) => {
  if (action.type === 'show') {
    return {
      key: action.key,
      show: true,
      option: action.option,
    };
  }
  return { show: false };
};

const SolutionData = ({ children }) => {
  const [data, dispatchData] = useReducer(reducer, { show: false });
  return (
    <SolutionContext.Provider value={{ data, dispatchData }}>
      {children}
    </SolutionContext.Provider>
  );
};

SolutionData.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export { SolutionContext };
export default SolutionData;
