import React from 'react';
import PropTypes from 'prop-types';
import check from './../../../res/images/benefits/check.svg';
import './checked-image.css';

const CheckedImage = ({ image, alt }) => {
  return (
    <>
      <div className={'check-bg'} style={{ backgroundImage: `url(${image})` }}>
        <img src={check} alt={'check'}/>
      </div>
    </>
  );
};

CheckedImage.propTypes = {
  image: PropTypes.any.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CheckedImage;
