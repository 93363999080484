import ChatApi from '../../../../api/ChatApi';
import { firebaseAnalytics } from '../../../../firebase/firebase';
const englishVoices = [{
  name: 'Joanna',
  provider: 'polly',
  language: 'en-US',
  engine: 'neural',
  key: 'Joanna',
}, {
  name: 'Ivy',
  provider: 'polly',
  language: 'en-US',
  engine: 'neural',
  key: 'Ivy',
}, {
  name: 'Justin',
  provider: 'polly',
  language: 'en-US',
  engine: 'neural',
  key: 'Justin',
}, {
  name: 'Matthew',
  provider: 'polly',
  language: 'en-US',
  engine: 'neural',
  key: 'Matthew',
}, {
  name: 'Kevin',
  provider: 'watson',
  language: 'en-US',
  key: 'en-US_KevinV3Voice',
}, {
  name: 'Lisa',
  provider: 'watson',
  language: 'en-US',
  key: 'en-US_LisaV3Voice',
}, {
  name: 'Emily',
  provider: 'watson',
  language: 'en-US',
  key: 'en-US_EmilyV3Voice',
}, {
  name: 'Michael',
  provider: 'watson',
  language: 'en-US',
  key: 'en-US_MichaelV3Voice',
}];

export const mappedEnglishVoices = englishVoices.map((it) => ({
  ...it, value: it.key, label: it.name,
}));

const spanishVoices = [{
  name: 'Lupe',
  provider: 'polly',
  language: 'es-US',
  engine: 'neural',
  key: 'Lupe',
  }, {
  name: 'Mia',
  provider: 'polly',
  language: 'es-MX',
  engine: 'standard',
  key: 'Mia',
  }, {
  name: 'Sofia',
  provider: 'watson',
  language: 'es-LA',
  key: 'es-LA_SofiaV3Voice',
}];

export const mappedSpanishVoices = spanishVoices.map((it) => ({
  ...it, value: it.key, label: it.name,
}));

const selectId = (key, language) => {
  if (language === 'es') {
    switch (key) {
      case 'smart-debt-collection':
        return 'cobranza';
      case 'smart-sale':
        return 'venta-llamada';
      case 'smart-customer-service':
        return 'servicio-llamada';
      default:
        return null;
    }
  } else { return `${key}-call`; }
};

export const selectDialogId = async (key, lang) => {
  const selectCollection = selectId(key, lang);
  firebaseAnalytics.logEvent(`${selectCollection}-call-website-clicked`);
  const dialogId = await ChatApi.getChatDetails(selectCollection)
  .then((data) => {
    const { data: info } = data;
    return info;
  })
  .catch(() => { return {}; });
  return dialogId;
};
