import React from 'react';
import { Button } from '@rmwc/button';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import SingleLanguageOnly from '../SingleLanguageOnly';
import Banner from '../../../res/images/intro/banner.svg';
// import Growth from '../../../res/images/intro/growth.svg';
import './intro.css';
import { firebaseAnalytics } from '../../../firebase/firebase';

const Intro = () => {
  const { t, i18n } = useTranslation();
  const language = i18n ? i18n.language || 'en' : 'en';

  const renderTyping = () => {
    return (<Typewriter
      options={{
        strings: [t('outstanding'), t('efficient'), t('sustainable')],
        autoStart: true,
        loop: true,
        delay: 75,
        deleteSpeed: 75,
      }}
    />);
  };

  return (<>
    <video poster={Banner} autoPlay={true} muted={true} loop={true} className={'intro-video'}>
      <source src={'/assets/videos/background.mp4'} type={'video/mp4'}/>
    </video>
    <div
      className={'section intro'} id={'#'}>
      <div className={'content'}>
        <div className={'use-baloo'} style={{ color: '#fff', lineHeight: '1.25' }}>
          <span className={'title'} style={{ fontWeight: 600 }}>
            {(language === 'en'
              ? [t('smart-solutions')]
              : t('smart-solutions').split(' ') || []).map((it, i) => {
              const last = i >= (t('smart-solutions').split(' ') || []).length - 1
                ? (<></>)
                : (<><br/></>);
              return (<React.Fragment key={i}>{it}{last}</React.Fragment>);
            })}
          </span>
          <br/>
          <span className={'title'} style={{ fontWeight: 500 }}>{t('for-results')}</span>
          <br/>
          <span className={'title'} style={{
            fontWeight: 500,
            color: 'var(--mdc-theme-secondary)',
          }}>
            {renderTyping()}
          </span>
          <SingleLanguageOnly lang={'en'}>
            <span className={'title'} style={{ fontWeight: 500 }}>{t('results')}</span>
          </SingleLanguageOnly>
        </div>
        <div className={'use-ubuntu'} style={{ color: '#fff', marginTop: '1rem' }}>
          <span style={{
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: '1.5',
          }}>
            {t('intro-content')}
          </span>
          <br/>
          <Button label={t('talk-to-experts')}
                  className={'rounded primary inverted'} raised
                  tag={'a'} href={'#contact'}
                  style={{ marginTop: '1.6rem' }}
                  onClick={() => firebaseAnalytics.logEvent('contact-website-visited')}/>
        </div>
      </div>
      <div className={'img-container'}>
        {/* <img src={Growth} alt={t('growth')}/> */}
      </div>
    </div>
  </>);
};

export default Intro;
