import React from 'react';
import PropTypes from 'prop-types';

const SiteBody = ({ children, style }) => {
  return (
    <div className={'mdc-body'} style={style}>
      {children}
    </div>
  );
};

SiteBody.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  style: PropTypes.object,
};

export default SiteBody;
