import { apiBotsmartDat, escalateError, getResponseData } from './index';

export default class CxSummitApi {
    static sendInfo(userData) {
        return apiBotsmartDat.post('alexaEvent', userData)
            .then(getResponseData)
            .catch(escalateError);
    }

    static getInfo() {
        return apiBotsmartDat.get('alexaEvent')
        .then(getResponseData)
        .catch(escalateError);
    }
}
