import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Snackbar } from '@rmwc/snackbar';
import EmailApi from '../../../api/EmailApi';
import Utils from '../../../utils/Utils';
import dotsBg from './../../../res/images/contact/dots-bg.svg';
import dotsBorder from './../../../res/images/benefits/dots-border.svg';
import { firebaseAnalytics } from '../../../firebase/firebase';
import './contact.css';

const Contact = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [ally, setAlly] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [enabled, setEnabled] = useState(true);

  const numberValue = (e) => {
    if (!(e.key >= 0 && e.key <= 9)) e.preventDefault();
  };

  const sendEmail = () => {
    if (!enabled) return;
    if (!name || name.length <= 0
      || !email || email.length <= 0
      || !message || message.length <= 0) {
      setSnackbarMessage(t('missing-fields'));
      return;
    }

    if (!Utils.validateEmail(email)) {
      setSnackbarMessage(t('invalid-email'));
      return;
    }

    if (phone && (phone.length < 9 || phone.length > 10)) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }
    setEnabled(false);
    EmailApi.sendEmail({ name, ally, email, phone, message })
      .then(() => {
        setName('');
        setAlly('');
        setEmail('');
        setPhone('');
        setMessage('');
        setSnackbarMessage(t('message-sent'));
        setEnabled(true);
        firebaseAnalytics.logEvent('message-contact-website-sent');
      })
      .catch(() => {
        setSnackbarMessage(t('message-error'));
        setEnabled(true);
        firebaseAnalytics.logEvent('message-contact-website-error');
      });
  };

  return (
    <div style={{ backgroundColor: '#f0f0f0' }} id={'contact'}>
      <div className={'section solutions'}>
        <div className={'column'} style={{ backgroundImage: `url(${dotsBg})` }}>
          <p className={'section-pre-title'}>{t('get-in')}</p>
          <p className={'section-title'} style={{ color: '#616161' }}>{t('touch')}</p>
          <br />
          <p className={'section-body'} style={{ maxWidth: '92%' }}>{t('contact-request')}</p>
        </div>
        <div className={'column right'} style={{ backgroundImage: `url(${dotsBg})` }}>
          <form>
            <Grid style={{ padding: 0 }}>
              <GridRow>
                <GridCell span={6}>
                  <TextField
                    disabled={!enabled} required
                    value={name} onChange={(e) => setName(e.target.value.toString())}
                    outlined placeholder={t('name')} label={t('name')} />
                </GridCell>
                <GridCell span={6}>
                  <TextField
                    disabled={!enabled}
                    value={ally} onChange={(e) => setAlly(e.target.value.toString())}
                    outlined placeholder={t('ally')} label={t('ally')} />
                </GridCell>
              </GridRow>
              <br />
              <GridRow>
                <GridCell span={6}>
                  <TextField
                    disabled={!enabled} required
                    value={email} onChange={(e) => setEmail(e.target.value.toString())}
                    outlined placeholder={t('email')} label={t('email')} type={'email'} />
                </GridCell>
                <GridCell span={6}>
                  <TextField
                    disabled={!enabled}
                    value={phone} onChange={(e) => setPhone(e.target.value.toString())}
                    onKeyPress={(e) => numberValue(e)} outlined min={0}
                    placeholder={t('phone')} label={t('phone')} type={'number'} />
                </GridCell>
              </GridRow>
              <br />
              <GridRow>
                <GridCell span={12}>
                  <TextField
                    disabled={!enabled} required
                    value={message} onChange={(e) => setMessage(e.target.value.toString())}
                    textarea outlined fullwidth
                    label={t('message')}
                    rows={4}
                    maxLength={500}
                    characterCount />
                </GridCell>
              </GridRow>
            </Grid>
            <p style={{
              fontFamily: 'var(--ubuntu-font)',
              color: 'var(--mdc-theme-error)',
              fontSize: '.8rem',
              marginTop: '.4rem',
            }}>
              * {t('required-fields')}
            </p>
            <br />
            <Button
              raised className={'rounded dark'} type={'button'}
              onClick={sendEmail}>
              {enabled ? t('send') : t('sending')}
            </Button>
          </form>
        </div>
      </div>
      <div className={'addresses'}>
        <div className={'address'}>
          <div className={'country'}><p>Perú</p></div>
          <div className={'details'}>
            <p>+511 7447984</p>
            <p>Calle Antero Aspillaga 277 - Of. 303</p>
            <p>San Isidro</p>
          </div>
        </div>
        <img src={dotsBorder} alt={'b'} />
        <div className={'address'}>
          <div className={'country'}><p>Colombia</p></div>
          <div className={'details'}>
            <p>+57 3106725387</p>
            <p>Calle 72 BIS # 6 - 44 - Of. 501</p>
            <p>Bogotá</p>
          </div>
        </div>
        <img src={dotsBorder} alt={'b'} />
        <div className={'address'}>
          <div className={'country'}><p>USA</p></div>
          <div className={'details'}>
            <p>+1 5109153509</p>
            <p>795 Folsom St 1st Floor</p>
            <p>San Francisco, CA, 94107</p>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarMessage.length > 0}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        dismissesOnAction />
    </div>
  );
};

export default Contact;
