import React, { useContext } from 'react';
import { Dialog } from '@rmwc/dialog';
import { SolutionContext } from './DialogProvider';
import DialogCall from './DialogCall';
import DialogInfo from './DialogInfo';
import './call-dialog.css';

const DialogWeb = () => {
  const { data, dispatchData } = useContext(SolutionContext);

  const renderDialog = () => {
    switch (data.option) {
      case 'chat':
        return (<DialogInfo option={'chat'}/>);
      case 'whatsapp':
        return (<DialogInfo option={'whatsapp'}/>);
      case 'call':
        return (<DialogCall/>);
      default:
        // eslint-disable-next-line no-console
        console.error('Unsupported Action');
        return (<>...</>);
    }
  };
  return (
    <Dialog
      open={data.show}
      onClose={() => { dispatchData(false); }}>
      {renderDialog()}
    </Dialog>
  );
};
export default DialogWeb;
