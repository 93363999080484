import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@rmwc/theme';
import {
  TopAppBar, TopAppBarActionItem, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection
} from '@rmwc/top-app-bar';
import { Button } from '@rmwc/button';
import { useTranslation } from 'react-i18next';
import DrawerContext from './drawer/DrawerContext';
import MDIcon from './MDIcon';
import BPSmartLogo from './../../res/images/bpsmart-logo.svg';
import BPSmartLogoInverted from './../../res/images/bpsmart-logo-inverted.svg';
import { firebaseAnalytics } from '../../firebase/firebase';

const ToolbarTheme = {
  primary: '#4d4d4d',
  primaryBg: '#fff',
  error: '#d43551',
  background: '#fff',
  onPrimary: '#4d4d4d',
};

const Toolbar = ({ useCustomTheme = true }) => {
  const { t, i18n } = useTranslation();
  const { setDrawerOpen } = useContext(DrawerContext);
  const ADMIN_URL = process.env;

  const changeLanguage = (code) => {
    if (i18n) i18n.changeLanguage(code);
    handleContent(`${code}-page`);
  };

  const handleContent = (section) => {
    firebaseAnalytics.logEvent(`${section}-website-visited`);
  };

  const renderToolbar = (content) => {
    if (useCustomTheme) {
      return (
        <ThemeProvider options={ToolbarTheme}>
          <TopAppBar style={{ background: '#fff' }}>
            {content}
          </TopAppBar>
        </ThemeProvider>
      );
    }
    return (<TopAppBar>{content}</TopAppBar>);
  };

  return (
    <>
      {renderToolbar(
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarActionItem
              icon={''} className={'block-mobile'}
              onClick={() => setDrawerOpen(true)}>
              <MDIcon icon={'menu'} />
            </TopAppBarActionItem>
            <Link to={'/#'} className={'toolbar-logo'} onClick={() => { window.scrollTo(0, 0); }}>
              <img alt={t('bpsmart-logo')}
                   src={useCustomTheme ? BPSmartLogo : BPSmartLogoInverted} />
            </Link>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <Button
              label={t('solutions')} className={'full-height'}
              tag={'a'} href={'/#solutions'}
              onClick={() => handleContent('solutions')} />
            <Button
              label={t('benefits')} className={'full-height'}
              tag={'a'} href={'/#benefits'}
              onClick={() => handleContent('benefits')} />
            <Button
              label={t('model')} className={'full-height'}
              tag={'a'} href={'/#model'}
              onClick={() => handleContent('model')} />
            <Button
              label={t('about')} className={'full-height'}
              tag={'a'} href={'/#about'}
              onClick={() => handleContent('about')} />
            <Button
              label={t('contact')} className={'primary rounded'} raised
              style={{ margin: '0 .4rem' }}
              tag={'a'} href={'/#contact'}
              onClick={() => handleContent('contact')} />
            <a href={ADMIN_URL.REACT_APP_ADMIN} target={'_blank'} rel={'noopener noreferrer'} >
              <Button label={t('clients-access')} className={'secondary rounded'} raised
                      style={{ margin: '0 .4rem' }}
                      onClick={() => handleContent('clients-access')} />
            </a>
            <Button label={'ESP | ENG'} className={'full-height'}
                    onClick={() => changeLanguage(i18n.language === 'es' ? 'en' : 'es')} />
          </TopAppBarSection>
        </TopAppBarRow>
      )}
      <TopAppBarFixedAdjust />
    </>
  );
};

Toolbar.propTypes = {
  useCustomTheme: PropTypes.bool,
};

export default Toolbar;
