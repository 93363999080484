import axios from 'axios';

const { REACT_APP_WEBSITE_BACKEND_SERVICE } = process.env;

const API = 'http://35.231.83.173:8080/api/';

const apiASTERISK = (process.env.REACT_APP_API_ASTERISK
  || 'https://asterisk-dot-bpsmart-prod-projects.appspot.com/api/');

const apiBOTSMART = (process.env.REACT_APP_API_BOTSMART
  || 'https://botsmart-dot-bpsmart-dev-projects.appspot.com/api/');

const apiURL = REACT_APP_WEBSITE_BACKEND_SERVICE;

const header = {
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('token'),
};

export const api = axios.create({
  baseURL: apiURL,
  headers: { 'Content-Type': 'application/json' },
});

export const apiAsteriskDat = axios.create({
  baseURL: apiASTERISK,
  headers: header,
});

export const apiCall = axios.create({
  baseURL: API,
  headers: header,
});

export const apiBotsmartDat = axios.create({
  baseURL: apiBOTSMART,
  headers: header,
});

export const getApiURL = () => apiURL;
export const getResponseData = (resp) => resp.data;

export const escalateError = (err) => {
  let errorFromResponse;
  try {
    errorFromResponse = err.response.data.error.toString();
  } catch (e) {
    errorFromResponse = undefined;
  }
  const newErr = new Error(errorFromResponse
    || (err instanceof Error
      ? err.message || err.toString()
      : typeof err === 'string'
        ? err
        : err.toString() || 'Error Inesperado'));
  try {
    newErr.data = err.response.data;
  } catch (e) {
  }
  throw newErr;
};

export default api;
