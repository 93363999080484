import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@rmwc/snackbar';
import { DialogContent } from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import DialogCalling from './DialogCalling';
import { SolutionContext } from './DialogProvider';
import { DataContext } from './DataContext';
import { ServicesApi } from '../../../../api/Services';
import { mappedEnglishVoices, mappedSpanishVoices, selectDialogId } from './CallUtils';
import { ReactComponent as CallSVG } from '../../../../res/images/solutions/call.svg';
import bpSmartCall from './../../../../res/images/solutions/Logo_BPS.svg';
import './call-dialog.css';

const DialogCall = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [voice, setVoice] = useState('');
  const [noise, setNoise] = useState('');
  const enabled = true;
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [voices, setVoices] = useState([]);
  const [callData, setCallData] = useState([]);
  const [show, setShow] = useState(false);
  const { data } = useContext(SolutionContext);
  const { dataUser, dispatchDataUser } = useContext(DataContext);
  const [sttLanguage, setSttLanguage] = useState('');
  const backgroundNoise = [{
    label: t('keyboard'),
    value: 'keyboard-typing',
  }, {
    label: t('pencil'),
    value: 'pen-writing',
  }, {
    label: t('morning'),
    value: 'morning',
  }, {
    label: t('coffe-shop'),
    value: 'coffe-shop',
  }];

  useEffect(() => {
    if (language.length > 0) {
      if ((language === 'en' && country !== '1') || (language === 'es' && country === '1')) {
        setSttLanguage('es-US');
      } else {
        switch (country) {
          case '57':
            setSttLanguage('es-CO');
            break;
          case '51':
            setSttLanguage('es-PE');
            break;
          default:
            setSttLanguage('en-US');
            break;
        }
      }
    }
  }, [language, country]);

  useEffect(() => {
    const { userData } = dataUser;
    const { clientName, number, country, lang, noise } = userData;
    if (dataUser.userData.length !== 0) {
      setName(clientName);
      setPhone(number);
      setCountry(country);
      setLanguage(lang);
      setNoise(noise);
    }
  }, [dataUser]);

  useMemo(() => {
    if (language && language.length > 0) {
      switch (language) {
        case 'en':
          setVoices(mappedEnglishVoices);
          break;
        case 'es':
          setVoices(mappedSpanishVoices);
          break;
        default:
          setVoices([]);
          break;
      }
    }
  }, [language]);

  const startCall = async () => {
    if (!enabled) return;
    if (!name || name.length <= 0
      || !phone || phone.length <= 0
      || !country || country.length <= 0
      || !language || language.length <= 0
      || !voice || voice.length <= 0) {
      setSnackbarMessage(t('missing-fields'));
      return;
    }

    if (country !== '51' && phone.length !== 10) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }
    if (country === '51' && phone.length !== 9) {
      setSnackbarMessage(t('invalid-number'));
      return;
    }
    ServicesApi();
    const dialogInfo = await selectDialogId(data.key, language);
    const [actualVoice] = voices.filter((it) => it.key === voice);
    // TODO: Update data to use correct values
    const { botId: dialogId } = dialogInfo;
    const { key, language: lang, engine, provider } = actualVoice;
    const callData = {
      afterGreetingSilence: '300',
      ally: 'bpsmart',
      amdUse: 'True',
      backgroundNoise: noise,
      betweenWordSilence: '50',
      botEngine: engine || 'standard',
      botLang: lang,
      botProvider: 'watson',
      botSttLang: sttLanguage,
      botSttProvider: 'watson',
      botTtsProvider: provider,
      botVoice: 'Lupe',
      callLimit: '60',
      campaignName: 'base interna',
      channel: 'websiteCalls',
      clientEmail: 'leonardo.talero@bpsmart.ai',
      clientId: `${country}${phone}`,
      clientIdMask: '13',
      clientName: name,
      clientPhone: `${country}${phone}`,
      dialogId,
      greeting: '1500',
      initialData: `holdingname ${name}`,
      initialSilence: '3000',
      interactionId: '#Abpsmart#CgZV8wjpbmdU9zy2NZvN9#CTPi#TKb28f1b8f-37b3-4769-8863-982ed3ac0553#'
      + 'Dc5807d5a-5da1-4ad3-ad64-cb173caa69eb#S1#TM20215102149#CII0cab1124-666d-4592-a687-d83890b'
      + '0f5c5#CUI8216356#CHvoice#CCHA8216356',
      ivr: 'False',
      maximumNumberOfWords: '4',
      minimumWordLength: '120',
      silenceAsHuman: 'False',
      silenceThreshold: '256',
      totalAnalysisTime: '5000',
      vadMode: 'AGGRESSIVE',
    };

    const userDataContext = {
      userData: {
        clientName: callData.clientName,
        number: phone,
        country,
        lang: language,
        noise,
        type: 'call',
        key: data.key,
        voice: key,
        startTime: new Date().toISOString(),
      },
    };

    dispatchDataUser(userDataContext);
    setCallData(callData);
    setShow(true);
  };

  return (<DialogContent>
    <img src={bpSmartCall} alt={'BPSmart Logo'} className={'call-dialog-logo'}/>
    <p>{t('add-info')}</p>
    <div className={'dialog-field'}>
      <TextField
        required outlined
        disabled={!enabled}
        value={name} onChange={(e) => setName(e.target.value.toString())}
        label={t('name')} placeholder={t('name')}
        type={'text'}/>
    </div>
    <div className={'dialog-field'}>
      <TextField
        required outlined
        disabled={!enabled}
        value={phone} onChange={(e) => setPhone(e.target.value.toString())}
        label={t('phone')} placeholder={t('phone')}
        type={'number'}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        disabled={!enabled}
        value={country} onChange={(e) => setCountry(e.target.value.toString())}
        label={t('country')}
        options={[{ label: 'Colombia', value: '57' },
          { label: 'Perú', value: '51' },
          { label: 'U.S.A', value: '1' }]}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        disabled={!enabled}
        value={language} onChange={(e) => setLanguage(e.target.value.toString())}
        label={t('language')}
        options={[{ label: t('spanish'), value: 'es' }, { label: t('english'), value: 'en' }]}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        disabled={!enabled || (!language || language.length <= 0)}
        value={voice} onChange={(e) => setVoice(e.target.value.toString())}
        label={t('voice')}
        options={voices}/>
    </div>
    <div className={'dialog-field'}>
      <Select
        enhanced required outlined
        value={noise} onChange={(e) => setNoise(e.target.value.toString())}
        label={t('noise')}
        options={backgroundNoise}/>
    </div>
    <div className={'dialog-field'}>
      <Button raised onClick={startCall}>
        <CallSVG/>
      </Button>
      <DialogCalling
        userData={callData}
        show={show}
        preventOutsideDismiss={true}
        />
    </div>
    <Snackbar
      open={snackbarMessage.length > 0}
      onClose={() => setSnackbarMessage('')}
      message={snackbarMessage}
      dismissesOnAction/>
  </DialogContent>);
};

export default DialogCall;
